const siteConfig = {
  appUrl: 'https://digital-programs-dashboard.staging.sidearmdev.com',
  apiUrl: 'https://digital-programs-api.staging.sidearmdev.com/api',
  sidearmApiUrl: 'https://api.sidearmdev.com/',
  apiKey: 'I6AaB1vFQq7Ix1PLthGLD5L6Bs0fw3Eg2n6g75LV',
  frontendUrl: 'https://digital-programs-frontend.staging.sidearmdev.com',
  cognito: {
    redirectURI: '/login/oauth2/code/cognito',
    redirectURISignout: '/logout',
    userPoolId: 'test-cognito',
    appDomain: 'victor-test.auth.us-west-2.amazoncognito.com',
    clientId: '48uq6gpe7s51kjri3pshs58gja',
  },
  aws: {
    digitalPrograms: {
      accessKey: 'AKIAVDTRJ6R7QTXZZFZ2',
      secretAccessKey: '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',
      region: 'us-east-1',
      bucket: 'victor-sidearm',
    },
    legacy: {
      accessKey: '',
      secretAccessKey: '',
      region: 'us-east-1',
      bucket: '',
    },
    nextgen: {
      accessKey: '',
      secretAccessKey: '',
      region: 'us-east-2',
      bucket: 'sidearm.nextgen.sites',
    },
    sqs: {
      accessKey: '',
      secretAccessKey: '',
      region: 'us-east-2',
      queueName: 'lfdp_pub_staging_queue',
    },
    cognito: {
      redirectURI: '/login/oauth2/code/cognito',
      redirectURISignout: '/logout',
      userPoolId: 'test-cognito',
      appDomain: 'victor-test.auth.us-west-2.amazoncognito.com',
      clientId: '48uq6gpe7s51kjri3pshs58gja',
    },
  },
};

export default siteConfig;
